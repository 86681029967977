import React, { FunctionComponent, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';

import Layout from '../components/layout';
import SEO from '../components/seo';
import BreakfastIcon from '../components/BreakfastIcon';
import SaladIcon from '../components/SaladIcon';
import SmoothieIcon from '../components/SmoothieIcon';
import SandwichIcon from '../components/SandwichIcon';
import Tab from '../components/Tab';

const IndexPage: FunctionComponent = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Layout>
      <SEO title="Home" />

      <div className="relative">
        <div className="hidden lg:block">
          <StaticImage
            alt=""
            className="hero-image"
            placeholder="blurred"
            src="../images/hero.png"
          />
        </div>

        <div className="block lg:hidden">
          <StaticImage
            alt=""
            className="mobile-hero w-full"
            placeholder="blurred"
            src="../images/hero-mobile.png"
          />
        </div>

        <div className="absolute bottom-0 hero-background left-0 right-0 top-0 lg:right-1/3 xl:right-1/2" />

        <div className="absolute left-0 px-4 right-0 text-center text-white top-1/2 transform -translate-y-1/2 lg:text-left xl:left-24">
          <h1 className="font-Parisienne hero-header text-6xl md:text-7xl xl:text-8xl">
            <span className="text-green">Quality </span>
            <span>You</span>
            <br />
            <span>Can </span>
            <span className="text-green">Taste.</span>
          </h1>

          <p className="font-medium text-lg">
            When it comes to quality, we don&apos;t stop at &quot;good&quot;. We aim for the best.
          </p>

          <div className="flex justify-center mt-8 lg:justify-start">
            <a
              className="bg-green mr-4 px-6 py-3 rounded-md text-white transition-opacity hover:opacity-70"
              href="https://order.ubereats.com/washington-dc/food-delivery/Natural%20Cafe%20(Arlington)/ukjUWM-yTFyEVNZHu2LcbA/?utm_source=web-restaurant-manager"
            >
              Order Online
            </a>

            <Link
              className="bg-green px-6 py-3 rounded-md text-white transition-opacity hover:opacity-70"
              to="/catering"
            >
              Event Catering
            </Link>
          </div>
        </div>
      </div>

      <div className="container mx-auto my-24 px-4">
        <div className="gap-8 grid items-center md:grid-cols-2 xl:gap-32">
          <div>
            <StaticImage alt="" src="../images/services.png" />
          </div>

          <div>
            <h3 className="text-green uppercase">Services We Offer</h3>
            <h2 className="font-Parisienne my-6 text-4xl lg:text-6xl">Event Catering</h2>

            <p className="font-light xl:w-2/3">
              Have a big event coming up? Let Natural Cafe take care of feeding your
              guests with our custom made catering platters and buffet selection.
            </p>

            <Link
              className="bg-green bg-opacity-20 border-2 border-green inline-block mt-6 px-6 py-2 rounded-md text-green transition-opacity hover:opacity-70"
              to="/catering"
            >
              Learn More
            </Link>
          </div>
        </div>
      </div>

      <div className="container mx-auto my-24 px-4">
        <div className="gap-8 grid grid-cols-1 items-center md:grid-cols-2 xl:gap-32">
          <div className="order-2 md:order-1">
            <h3 className="text-green uppercase">The Market</h3>
            <h2 className="font-Parisienne my-6 text-4xl lg:text-6xl">Natural Market</h2>

            <p className="font-light xl:w-2/3">
              Our market has plenty to offer, with great things for any time of the day.
              Breakfast items, sandwiches, soups, paninis, and salads are right here waiting.
              Also featured on our menu are our high quality, organic gourmet foods and
              grocery products.
            </p>

            <Link
              className="bg-green bg-opacity-20 border-2 border-green inline-block mt-6 px-6 py-2 rounded-md text-green transition-opacity hover:opacity-70"
              to="/about"
            >
              Learn More
            </Link>
          </div>

          <div className="relative max-w-full mx-auto order-1 lg:order-2">
            <div className="absolute bg-green h-24 -left-4 -top-4 w-24 z-0" />
            <div className="absolute bg-green -bottom-4 h-24 -right-4 w-24 z-0" />

            <div className="bg-white p-4 relative rounded-md shadow-lg z-10">
              <Swiper
                autoplay={{
                  delay: 5000,
                }}
                modules={[Autoplay]}
                slidesPerView={1}
              >
                <SwiperSlide>
                  <StaticImage alt="" loading="eager" placeholder="blurred" src="../images/slide-4.png" />
                </SwiperSlide>

                <SwiperSlide>
                  <StaticImage alt="" loading="eager" placeholder="blurred" src="../images/slide-2.png" />
                </SwiperSlide>

                <SwiperSlide>
                  <StaticImage alt="" loading="eager" placeholder="blurred" src="../images/slide-1.png" />
                </SwiperSlide>

                <SwiperSlide>
                  <StaticImage alt="" loading="eager" placeholder="blurred" src="../images/slide-3.png" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      <div className="-mt-24 pt-24" id="menus">
        <div className="bg-beige container mx-auto py-8 md:py-12 lg:py-24">
          <div className="px-4">
            <h2 className="font-Parisienne my-4 text-center text-5xl md:mb-8 lg:text-6xl">Our Menus</h2>

            <div aria-label="Menu" className="gap-4 grid grid-flow-col max-w-4xl mx-auto overflow-x-auto py-4" role="tablist">
              <Tab
                active={activeTab === 0}
                id="breakfast-menu"
                onClick={() => setActiveTab(0)}
              >
                <div className="flex h-24 items-center justify-center mx-auto w-24">
                  <BreakfastIcon />
                </div>

                <p>Breakfast Menu</p>
              </Tab>

              <Tab
                active={activeTab === 1}
                id="salad-menu"
                onClick={() => setActiveTab(1)}
              >
                <div className="flex h-24 items-center justify-center mx-auto w-24">
                  <SaladIcon />
                </div>

                <p>Salad Menu</p>
              </Tab>

              <Tab
                active={activeTab === 2}
                id="sandwich-menu"
                onClick={() => setActiveTab(2)}
              >
                <div className="flex h-24 items-center justify-center mx-auto w-24">
                  <SandwichIcon />
                </div>

                <p>Sandwich Menu</p>
              </Tab>

              <Tab
                active={activeTab === 3}
                id="smoothie-menu"
                onClick={() => setActiveTab(3)}
              >
                <div className="flex h-24 items-center justify-center mx-auto w-24">
                  <SmoothieIcon />
                </div>

                <p>Smoothie Menu</p>
              </Tab>
            </div>

            <div
              aria-labelledby="breakfast-menu"
              className="bg-white max-w-5xl mt-8 mx-auto px-8 py-4 rounded-md shadow-lg tab-panel"
              hidden={activeTab !== 0}
              id="breakfast-menu-tab"
              role="tabpanel"
              tabIndex={0}
            >
              <div className="border-b-2 border-grey flex font-light mb-4 pb-1 text-xl">
                <span className="flex-1">Breakfast Menu</span>
                <span>Price</span>
              </div>

              <p className="font-bold">Sandwiches</p>

              <ul className="font-light mb-4 mt-2 pl-4 md:pl-8 md:text-lg">
                {[
                  {
                    name: 'Egg or Meat Only',
                    price: 2.99,
                  },
                  {
                    name: 'Egg & Cheese',
                    price: 3.49,
                  },
                  {
                    name: 'Grilled Cheese',
                    price: 3.49,
                  },
                  {
                    name: 'Meat & Egg',
                    price: 3.99,
                  },
                  {
                    name: 'Sausage, Egg & Cheese',
                    price: 4.29,
                  },
                  {
                    name: 'Bacon, Egg & Cheese',
                    price: 4.29,
                  },
                  {
                    name: 'Ham, Egg & Cheese',
                    price: 4.29,
                  },
                ].map((elem) => (
                  <li className="flex items-center" key={elem.name}>
                    <span>{elem.name}</span>
                    <span className="border-b-2 border-dashed border-grey flex-1 mx-4" />
                    <span>{`$${elem.price}`}</span>
                  </li>
                ))}
                <li className="text-sm">*Choice of bread: Bagel, Croissant, English Muffin</li>
                <li className="text-sm">*Includes choice of regular bread</li>
              </ul>

              <p className="font-bold">Omelets</p>

              <ul className="font-light mb-4 mt-2 pl-4 md:pl-8 md:text-lg">
                {[
                  {
                    name: 'Cheese Omelet',
                    price: 4.29,
                  },
                  {
                    name: 'Veggie Omelet',
                    price: 4.49,
                  },
                  {
                    name: 'Ham & Cheese Omelet',
                    price: 4.49,
                  },
                  {
                    name: 'Western Omelet (Ham, Peppers, Mushroom, Red Onion & Cheese)',
                    price: 4.99,
                  },
                ].map((elem) => (
                  <li className="flex items-center" key={elem.name}>
                    <span>{elem.name}</span>
                    <span className="border-b-2 border-dashed border-grey flex-1 mx-4" />
                    <span>{`$${elem.price}`}</span>
                  </li>
                ))}
              </ul>

              <p className="font-bold">Bagels</p>

              <ul className="font-light mb-4 mt-2 pl-4 md:pl-8 md:text-lg">
                {[
                  {
                    name: 'Plain Bagel',
                    price: 0.99,
                  },
                  {
                    name: 'Butter or Jelly Bagel',
                    price: 1.59,
                  },
                  {
                    name: 'Bagel with Cream Cheese',
                    price: 2.19,
                  },
                  {
                    name: 'Bagel with Lox',
                    price: 5.49,
                  },
                ].map((elem) => (
                  <li className="flex items-center" key={elem.name}>
                    <span>{elem.name}</span>
                    <span className="border-b-2 border-dashed border-grey flex-1 mx-4" />
                    <span>{`$${elem.price}`}</span>
                  </li>
                ))}
                <li className="text-sm">*EXTRA: Meat, Bacon, Egg, Cream Cheese $1.00</li>
                <li className="text-sm">*EXTRA: Cheese, Veggie, Avocado, Jam $0.50</li>
              </ul>
            </div>

            <div
              aria-labelledby="salad-menu"
              className="bg-white max-w-5xl mt-8 mx-auto px-8 py-4 rounded-md shadow-lg tab-panel"
              hidden={activeTab !== 1}
              id="salad-menu-tab"
              role="tabpanel"
              tabIndex={0}
            >
              <div className="border-b-2 border-grey flex font-light mb-4 pb-1 text-xl">
                <span className="flex-1">Salad Menu</span>
                <span>Price</span>
              </div>

              <ul className="font-light md:text-lg">
                {[
                  {
                    description: 'Romaine lettuce, croutons & parmesan cheese',
                    name: 'Caesar Salad',
                    price: 5.99,
                  },
                  {
                    description: 'Lettuce, tomato, red onion, cucumber, red pepper, kalamata olive, feta cheese & boiled egg',
                    name: 'Greek Salad',
                    price: 6.99,
                  },
                  {
                    description: 'Lettuce, tomato, cucumber, swiss cheese, ham, turkey & boiled egg',
                    name: 'Chef Salad',
                    price: 7.99,
                  },
                  {
                    description: 'Grilled chicken, lettuce, tomato, avocado, boiled egg, kalamata olive, feta cheese',
                    name: 'Cobb Salad',
                    price: 7.99,
                  },
                  {
                    description: 'Grilled chicken, lettuce, spinach, apple, walnut, feta cheese',
                    name: 'Harvest Cobb Salad',
                    price: 7.99,
                  },
                  {
                    description: 'Lettuce, tomato, cucumber, carrot (Choice of grilled, chicken salad or tuna salad)',
                    name: 'Spa Salad',
                    price: 7.99,
                  },
                ].map((elem) => (
                  <li className="flex mb-2" key={elem.name}>
                    <div className="flex-1">
                      <div className="flex items-center">
                        <span className="font-bold">{elem.name}</span>
                        <span className="border-b-2 border-dashed border-grey flex-1 mx-4" />
                      </div>

                      <p className="pl-4 pr-4 text-base md:pl-8">{elem.description}</p>
                    </div>

                    <span>{`$${elem.price}`}</span>
                  </li>
                ))}
                <li className="text-sm">*EXTRA: Meet $2.00, Salmon $3.00, Boiled Egg $0.75</li>
              </ul>
            </div>

            <div
              aria-labelledby="sandwich-menu"
              className="bg-white max-w-5xl mt-8 mx-auto px-8 py-4 rounded-md shadow-lg tab-panel"
              hidden={activeTab !== 2}
              id="sandwich-menu-tab"
              role="tabpanel"
              tabIndex={0}
            >
              <div className="border-b-2 border-grey flex font-light mb-4 pb-1 text-xl">
                <span className="flex-1">Sandwich Menu</span>
                <span>Price</span>
              </div>

              <p className="font-bold">Hot Specialty Sandwiches</p>

              <ul className="font-light mb-4 mt-2 pl-4 md:pl-8 md:text-lg">
                {[
                  {
                    description: 'Roast beef, turkey, melted provolone cheese & russian dressing on a sub (white & wheat)',
                    name: 'San Francisco',
                    price: 7.49,
                  },
                  {
                    description: 'Chicken breast, melted mozzarella cheese, marinara sauce, grated parmesan cheese on a sub (white & wheat)',
                    name: 'Chicken Parmesan',
                    price: 7.49,
                  },
                  {
                    description: 'Pastrami, melted swiss cheese, coleslaw & russian dressing on a marble rye',
                    name: 'New Yorker',
                    price: 7.49,
                  },
                  {
                    description: 'Corned beef, melted swiss cheese, sauerkraut & russian dressing on a marble rye',
                    name: 'Reuben',
                    price: 7.49,
                  },
                  {
                    description: 'Tuna salad, melted provolone cheese, lettuce, tomato, alfalfa sprouts, red onion on a marble rye',
                    name: 'Tuna Melt',
                    price: 7.49,
                  },
                  {
                    description: 'Crispy bacon, lettuce, tomato, mayo on a choice of bread',
                    name: 'B.L.T',
                    price: 6.49,
                  },
                ].map((elem) => (
                  <li className="mb-2" key={elem.name}>
                    <div className="flex items-center">
                      <span className="font-bold">{elem.name}</span>
                      <span className="border-b-2 border-dashed border-grey flex-1 mx-4" />
                      <span>{`$${elem.price}`}</span>
                    </div>

                    <p className="pl-4 text-base md:pl-8">{elem.description}</p>
                  </li>
                ))}
              </ul>

              <p className="font-bold">Specialty Sandwiches</p>

              <ul className="font-light mb-4 mt-2 pl-4 md:pl-8 md:text-lg">
                {[
                  {
                    description: 'Ham, Genoa salami, provolone cheese, lettuce, tomato, red onion, pickle, hot and sweet pepper, olive oil & vinegar on a sub (white & wheat)',
                    name: 'Italian',
                    price: 7.49,
                  },
                  {
                    description: 'Smoked turkey, crispy bacon, lettuce, tomato, red onion, avocado & mayo on a sub (white & wheat)',
                    name: 'Cabbie',
                    price: 7.49,
                  },
                  {
                    description: 'Smoked turkey, smoked gouda cheese, spinach, red onion, roasted red pepper on multigrain',
                    name: 'Gouda Gobblers',
                    price: 7.49,
                  },
                  {
                    description: 'Smoked turkey, fresh mozzarella cheese, artichoke, roasted red pepper & basil pesto on a sourdough',
                    name: 'Isabella',
                    price: 7.49,
                  },
                  {
                    description: 'Ham, turkey, crispy bacon, lettuce, tomato & mayo on a toasted',
                    name: 'Club',
                    price: 7.49,
                  },
                ].map((elem) => (
                  <li className="flex mb-2" key={elem.name}>
                    <div className="flex-1">
                      <div className="flex items-center">
                        <span className="font-bold">{elem.name}</span>
                        <span className="border-b-2 border-dashed border-grey flex-1 mx-4" />
                      </div>

                      <p className="pl-4 pr-4 text-base md:pl-8">{elem.description}</p>
                    </div>

                    <span>{`$${elem.price}`}</span>
                  </li>
                ))}
              </ul>

              <p className="font-bold">Vegetarian Specialty Sandwiches</p>

              <ul className="font-light mb-4 mt-2 pl-4 md:pl-8 md:text-lg">
                {[
                  {
                    description: 'Lettuce, tomato, red onion, alfalfa sprouts, cucumber, avocado, spinach on a choice of bread',
                    name: 'Veggie',
                    price: 6.49,
                  },
                  {
                    description: 'Hummus, tomato, red onion, alfalfa sprouts, cucumber, avocado, artichoke, on a pita bread',
                    name: 'Mediterranean Middy',
                    price: 6.99,
                  },
                  {
                    description: 'Fresh mozzarella cheese, artichoke, spinach, roasted red pepper & basil pesto on a choice of bread',
                    name: 'Sausalito Veggie',
                    price: 6.49,
                  },
                ].map((elem) => (
                  <li className="flex mb-2" key={elem.name}>
                    <div className="flex-1">
                      <div className="flex items-center">
                        <span className="font-bold">{elem.name}</span>
                        <span className="border-b-2 border-dashed border-grey flex-1 mx-4" />
                      </div>

                      <p className="pl-4 pr-4 text-base md:pl-8">{elem.description}</p>
                    </div>

                    <span>{`$${elem.price}`}</span>
                  </li>
                ))}
              </ul>

              <p className="font-bold">Sandwiches</p>

              <ul className="font-light mb-4 mt-2 pl-4 md:pl-8 md:text-lg">
                {[
                  {
                    breadPrice: 6.49,
                    name: 'Turkey Breast',
                    subPrice: 7.49,
                  },
                  {
                    breadPrice: 6.49,
                    name: 'Maple Honey Turkey',
                    subPrice: 7.49,
                  },
                  {
                    breadPrice: 6.49,
                    name: 'Mesquite Smoked Turkey',
                    subPrice: 7.49,
                  },
                  {
                    breadPrice: 6.49,
                    name: 'Salsalito Turkey',
                    subPrice: 7.49,
                  },
                  {
                    breadPrice: 6.49,
                    name: 'Cajun Turkey',
                    subPrice: 7.49,
                  },
                  {
                    breadPrice: 6.49,
                    name: 'Ham',
                    subPrice: 7.49,
                  },
                  {
                    breadPrice: 6.49,
                    name: 'Roast Chicken Breast',
                    subPrice: 7.49,
                  },
                  {
                    breadPrice: 6.49,
                    name: 'Everroast Chicken Breast',
                    subPrice: 7.49,
                  },
                  {
                    breadPrice: 6.49,
                    name: 'Roast Beef',
                    subPrice: 7.49,
                  },
                  {
                    breadPrice: 6.49,
                    name: 'Corned Beef',
                    subPrice: 7.49,
                  },
                  {
                    breadPrice: 6.49,
                    name: 'Pastrami',
                    subPrice: 7.49,
                  },
                  {
                    breadPrice: 5.99,
                    name: 'Cheese',
                    subPrice: 7.49,
                  },
                  {
                    breadPrice: 6.49,
                    name: 'Chicken Salad',
                    subPrice: 7.49,
                  },
                  {
                    breadPrice: 6.49,
                    name: 'Tuna Salad',
                    subPrice: 7.49,
                  },
                  {
                    breadPrice: 5.99,
                    name: 'Egg Salad',
                    subPrice: 7.49,
                  },
                ].map((elem) => (
                  <li className="flex flex-wrap items-center mb-2 sm:flex-nowrap" key={elem.name}>
                    <span className="font-bold w-full sm:w-auto">{elem.name}</span>
                    <span className="border-b-2 border-dashed border-grey flex-1 hidden mx-4 sm:block" />
                    <span className="pl-4 sm:pl-0">{`Bread $${elem.breadPrice} | Sub/Wrap $${elem.subPrice}`}</span>
                  </li>
                ))}
              </ul>

              <p className="font-bold">Hot Panini&apos;s</p>

              <ul className="font-light mb-4 mt-2 pl-4 md:pl-8 md:text-lg">
                {[
                  {
                    description: 'Turkey, provolone cheese, crispy bacon, tomato, roasted red pepper & russian dressing',
                    name: 'P1',
                    price: 7.49,
                  },
                  {
                    description: 'Ham, turkey, swiss cheese, crispy bacon & sun dried tomato pesto',
                    name: 'P2',
                    price: 7.49,
                  },
                  {
                    description: 'Roast beef, cheddar cheese, tomato, olive oil & sun dried tomato pesto',
                    name: 'P3',
                    price: 7.49,
                  },
                  {
                    description: 'Grilled chicken, fresh mozzarella cheese, sun dried tomato & basil pesto',
                    name: 'P4',
                    price: 7.49,
                  },
                  {
                    description: 'Cajun turkey, pepper jack cheese, roasted red pepper, sun dried tomato & honey mustard',
                    name: 'P5',
                    price: 7.49,
                  },
                  {
                    description: 'Fresh mozzarella cheese, tomato, spinach, roasted red pepper & basil pesto',
                    name: 'P6',
                    price: 7.49,
                  },
                ].map((elem) => (
                  <li className="flex mb-2" key={elem.name}>
                    <div className="flex-1">
                      <div className="flex items-center">
                        <span className="font-bold">{elem.name}</span>
                        <span className="border-b-2 border-dashed border-grey flex-1 mx-4" />
                      </div>

                      <p className="pl-4 pr-4 text-base md:pl-8">{elem.description}</p>
                    </div>

                    <span>{`$${elem.price}`}</span>
                  </li>
                ))}
              </ul>

              <p className="text-sm">*Choice of bread: White, Wheat, Multi Grain, Sourdough, Marble Rye</p>
            </div>

            <div
              aria-labelledby="smoothie-menu"
              className="bg-white max-w-5xl mt-8 mx-auto px-8 py-4 rounded-md shadow-lg tab-panel"
              hidden={activeTab !== 3}
              id="smoothie-menu-tab"
              role="tabpanel"
              tabIndex={0}
            >
              <div className="border-b-2 border-grey flex font-light mb-4 pb-1 text-xl">
                <span className="flex-1">Smoothie Menu</span>
                <span>Price</span>
              </div>

              <ul className="font-light">
                {[
                  {
                    description: 'Mango, banana, pineapple, honey, non-fat yogurt & crashed ice',
                    name: 'Mid-Day Energizer',
                    price: 4.69,
                  },
                  {
                    description: 'Blueberry, banana, honey, non-fat yogurt & crashed ice',
                    name: 'Midnight Blue',
                    price: 4.69,
                  },
                  {
                    description: 'Peanut butter, banana, honey, non-fat yogurt & crashed ice',
                    name: 'School Days',
                    price: 4.69,
                  },
                  {
                    description: 'Strawberry, banana, honey, non-fat yogurt & crashed ice',
                    name: 'Daytime Zinger',
                    price: 4.69,
                  },
                ].map((elem) => (
                  <li className="flex mb-2" key={elem.name}>
                    <div className="flex-1">
                      <div className="flex items-center">
                        <span className="font-bold">{elem.name}</span>
                        <span className="border-b-2 border-dashed border-grey flex-1 mx-4" />
                      </div>

                      <p className="pl-4 pr-4 text-base md:pl-8">{elem.description}</p>
                    </div>

                    <span>{`$${elem.price}`}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="my-24">
            <div className="gap-8 grid items-center md:grid-cols-2 xl:gap-32">
              <div className="order-2 px-4 md:order-1 md:pr-0 xl:pl-16">
                <h3 className="text-green uppercase">About Us</h3>
                <h2 className="font-Parisienne my-6 text-4xl lg:text-6xl">Our Story</h2>

                <p className="font-light xl:w-2/3">
                  Natural Cafe opened in Arlington, VA in 2014.
                  Our mission at Natural Cafe is to offer our customers
                  wholesome and delicious deli meats and cheeses, accompanied
                  with excellent customer service.
                </p>

                <Link
                  className="bg-green bg-opacity-20 border-2 border-green inline-block mt-6 px-6 py-2 rounded-md text-green transition-opacity hover:opacity-70"
                  to="/about"
                >
                  Learn More
                </Link>
              </div>

              <div className="order-1 pl-4 md:order-2 md:pl-0">
                <StaticImage alt="" src="../images/about.png" />
              </div>
            </div>
          </div>

          <div className="my-12 relative md:my-24">
            <div className="hidden justify-between xl:flex">
              <div>
                <StaticImage alt="" src="../images/sandwich.png" />
              </div>

              <div>
                <StaticImage alt="" src="../images/wrap.png" />
              </div>

              <div className="absolute grid grid-cols-2 h-full skew-overlay z-10">
                <div className="bg-green-light skew-block">
                  <div className="flex h-full items-center justify-center text-center un-skew-block">
                    <div className="p-8">
                      <h2 className="font-Parisienne text-4xl">Build your sandwich</h2>
                      <p className="my-6">Build your perfect sandwich!</p>

                      <a
                        className="bg-green inline-block px-6 py-2 rounded-md text-white transition-opacity hover:opacity-70"
                        href="https://order.ubereats.com/washington-dc/food-delivery/Natural%20Cafe%20(Arlington)/ukjUWM-yTFyEVNZHu2LcbA/?utm_source=web-restaurant-manager"
                      >
                        Start Order
                      </a>
                    </div>
                  </div>
                </div>

                <div className="bg-white skew-block">
                  <div className="flex h-full items-center justify-center text-center un-skew-block">
                    <div className="p-8">
                      <h2 className="font-Parisienne text-4xl">Event Catering</h2>
                      <p className="my-6">Let us help make your event special!</p>

                      <Link
                        className="bg-green inline-block px-6 py-2 rounded-md text-white transition-opacity hover:opacity-70"
                        to="/catering"
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="xl:hidden">
              <div className="grid md:grid-cols-2">
                <div>
                  <StaticImage alt="" src="../images/sandwich.png" />
                </div>

                <div className="bg-green-light">
                  <div className="flex h-full items-center justify-center text-center">
                    <div className="p-8">
                      <h2 className="font-Parisienne text-4xl lg:text-5xl">Build your sandwich</h2>
                      <p className="my-6">Build your perfect sandwich!</p>

                      <a
                        className="bg-green inline-block px-6 py-2 rounded-md text-white transition-opacity hover:opacity-70"
                        href="https://order.ubereats.com/washington-dc/food-delivery/Natural%20Cafe%20(Arlington)/ukjUWM-yTFyEVNZHu2LcbA/?utm_source=web-restaurant-manager"
                      >
                        Start Order
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid md:grid-cols-2">
                <div className="md:order-2">
                  <StaticImage alt="" src="../images/wrap.png" />
                </div>

                <div className="bg-white md:order-1">
                  <div className="flex h-full items-center justify-center text-center">
                    <div className="p-8">
                      <h2 className="font-Parisienne text-4xl lg:text-5xl">Event Catering</h2>
                      <p className="my-6">Let us help make your event special!</p>

                      <Link
                        className="bg-green inline-block px-6 py-2 rounded-md text-white transition-opacity hover:opacity-70"
                        to="/catering"
                      >
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="gap-16 grid items-center -mt-36 pt-36 px-4 lg:grid-cols-2" id="hours">
            <div className="mx-auto relative">
              <iframe
                className="max-w-full relative shadow-md z-10"
                height="450"
                loading="lazy"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDP8goJrDvENYjSXSeCtnzeI8J7Xb1tiqU&amp;q=Natural+Cafe+Market,Arlington+VA"
                title="Location Map"
                width="600"
              />

              <div className="absolute bg-green h-24 -left-4 rounded-md w-24 -top-4" />
              <div className="absolute bg-green -bottom-4 h-24 -right-4 rounded-md w-24" />
            </div>

            <div className="text-center lg:text-left">
              <h3 className="text-green uppercase">Location &amp; Hours</h3>

              <div className="font-light grid md:grid-cols-2">
                <div>
                  <h2 className="font-Parisienne my-4 text-4xl">Hours of Operation</h2>

                  <div className="mb-4">
                    <p>Monday - Thursday</p>
                    <p>6:30am - 10:30pm</p>
                  </div>

                  <div className="mb-4">
                    <p>Friday</p>
                    <p>6:30am - 10:30pm</p>
                  </div>

                  <div className="mb-4">
                    <p>Saturday</p>
                    <p>8:00am - 10:30pm</p>
                  </div>

                  <div className="mb-4">
                    <p>Sunday</p>
                    <p>8:00am - 10:00pm</p>
                  </div>
                </div>

                <div>
                  <h2 className="font-Parisienne my-4 text-4xl">Address</h2>

                  <p>850 N Randolph Street #119</p>
                  <p>Arlington, VA 22203</p>

                  <h2 className="font-Parisienne my-4 text-4xl">Contact</h2>

                  <a href="tel:7035282223">(703) 528-2223</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
