import React, { FunctionComponent } from 'react';

const SandwichIcon: FunctionComponent = () => (
  <svg width="88" height="54" viewBox="0 0 88 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0951 24.067C14.0221 24.492 15.9431 24.942 17.8631 25.393C20.7441 26.07 23.7241 26.77 26.6881 27.368C32.9201 28.624 39.5011 29.753 46.8091 30.819L48.5161 31.07C52.9571 31.729 57.5501 32.411 62.1371 32.411C63.6391 32.411 65.0951 32.336 66.4671 32.189C70.9581 31.71 74.5821 29.334 77.8291 26.838L77.9481 26.746C81.0041 24.398 85.6211 20.85 84.5651 15.941C82.9841 8.579 74.0521 6.461 68.0871 5.045C62.0951 3.624 54.9451 2.059 47.3581 1.221L45.6711 1.033C44.0691 0.853001 42.4671 0.674001 40.8631 0.515001C37.3191 0.164001 34.4451 0 31.8191 0C26.7861 0 22.4581 0.616001 18.5861 1.884C15.3961 2.929 12.5851 4.486 10.2311 6.511C8.28912 8.183 3.86112 12.634 4.69312 17.85C5.18312 20.917 7.81112 23.124 12.0951 24.067ZM11.8621 8.408C13.9651 6.598 16.4881 5.204 19.3641 4.262C22.9801 3.078 27.0541 2.502 31.8191 2.502C34.3611 2.502 37.1561 2.662 40.6171 3.005C42.2101 3.162 43.8021 3.34 45.3931 3.519L47.0821 3.707C54.5351 4.53 61.5911 6.075 67.5631 7.491C72.8621 8.748 80.8701 10.647 82.1201 16.467C82.8431 19.831 79.1331 22.682 76.4241 24.764L76.3041 24.856C73.3471 27.128 70.0751 29.289 66.2001 29.703C64.9161 29.841 63.5491 29.911 62.1361 29.911C57.7341 29.911 53.2341 29.243 48.8811 28.598L47.1691 28.345C39.9061 27.285 33.3681 26.164 27.1811 24.917C24.2561 24.327 21.2961 23.633 18.4341 22.96C16.5021 22.507 14.5711 22.053 12.6331 21.626C10.3611 21.125 7.56812 19.996 7.16212 17.459C6.51112 13.376 10.5951 9.499 11.8621 8.408Z" fill="currentColor" />
    <path d="M79.8701 38.604C79.1791 38.536 78.6041 39.125 78.5831 39.815C78.5121 42.138 77.3951 44.603 75.7351 46.095C72.8171 48.719 69.7871 50.119 66.2001 50.502C60.4691 51.115 54.5791 50.241 48.8811 49.397L47.1691 49.144C39.9061 48.084 33.3681 46.963 27.1811 45.716C24.2541 45.126 21.2921 44.43 18.4271 43.758C16.4981 43.305 14.5691 42.852 12.6331 42.425C10.2601 41.902 7.38715 40.769 7.17315 38.345C7.13315 37.895 7.09015 37.131 7.09015 37.127L7.07415 37.128C7.07215 37.109 7.07915 37.094 7.07715 37.075C6.98015 36.393 6.34815 35.919 5.66515 36.016C4.98215 36.113 4.49615 36.584 4.59315 37.267C4.59315 37.267 4.63915 38.082 4.68115 38.563C4.95215 41.633 7.58415 43.871 12.0931 44.866C14.0181 45.29 15.9361 45.741 17.8541 46.191C20.7381 46.868 23.7201 47.568 26.6861 48.167C32.9181 49.423 39.4991 50.552 46.8071 51.618L48.5131 51.869C52.9711 52.53 57.5441 53.209 62.1421 53.209C63.5811 53.209 65.0241 53.143 66.4651 52.988C70.5441 52.551 74.1231 50.905 77.4061 47.954C79.5811 45.997 80.9881 42.908 81.0801 39.891C81.1031 39.201 80.5611 38.625 79.8701 38.604Z" fill="currentColor" />
    <path d="M29.6101 31.063C10.0161 28.405 7.71406 25.475 7.63306 24.339C7.58406 23.65 6.98706 23.135 6.29806 23.18C5.61006 23.229 5.09006 23.827 5.13906 24.516C5.42806 28.585 12.6461 31.285 29.2741 33.539C29.8711 33.62 30.3401 33.684 30.6491 33.731C30.7131 33.741 30.7781 33.746 30.8411 33.746C31.4471 33.746 31.9801 33.304 32.0751 32.686C32.1801 32.003 31.7121 31.365 31.0301 31.26C30.7111 31.212 30.2261 31.146 29.6101 31.063Z" fill="currentColor" />
    <path d="M58.6171 37.325C59.2551 37.362 59.8551 37.406 60.4391 37.449C61.6911 37.542 62.8861 37.631 64.2211 37.631C64.5081 37.631 64.8011 37.627 65.1031 37.617C67.2441 37.554 69.4291 37.123 71.5981 36.335C74.1701 35.402 76.5711 34.035 78.5791 32.819C81.0031 31.352 83.2911 29.586 83.6171 26.62C83.6931 25.933 83.1981 25.316 82.5121 25.24C81.8251 25.169 81.2081 25.66 81.1331 26.346C80.9371 28.129 79.4521 29.368 77.2841 30.68C75.3801 31.832 73.1141 33.125 70.7451 33.985C68.8261 34.682 66.9031 35.063 65.0291 35.119C63.4541 35.165 62.0791 35.065 60.6231 34.956C60.0251 34.912 59.4121 34.867 58.7611 34.829C58.0781 34.805 57.4811 35.316 57.4411 36.005C57.4011 36.694 57.9281 37.285 58.6171 37.325Z" fill="currentColor" />
    <path d="M55.6661 32.8331L46.1141 38.8681C43.9541 37.1651 37.3921 31.9571 35.8021 30.2971C35.3231 29.7961 34.5311 29.7801 34.0341 30.2591C33.5351 30.7361 33.5181 31.5281 33.9961 32.0261C36.0201 34.1401 44.8881 41.0871 45.2641 41.3811L45.9571 41.9241L57.0011 34.9461C57.5841 34.5781 57.7591 33.8051 57.3901 33.2211C57.0221 32.6381 56.2521 32.4661 55.6661 32.8331Z" fill="currentColor" />
    <path d="M5.5821 32.444C5.6551 32.59 5.7041 32.819 5.7561 33.061C5.8401 33.451 5.9441 33.936 6.2141 34.412C7.1721 36.096 9.2891 36.421 10.8881 35.871C11.4511 35.678 11.9431 35.407 12.4191 35.146C13.1081 34.769 13.6841 34.442 14.3001 34.47C14.6791 34.487 15.0131 34.875 15.4921 35.856C17.2681 39.492 20.4101 38.219 22.2881 37.458C22.8041 37.25 23.2901 37.053 23.7431 36.924C23.9751 36.856 24.7731 36.67 25.2531 37.071C25.4141 37.205 25.5611 37.517 25.7161 37.848C26.0191 38.494 26.4351 39.378 27.4281 39.855C27.9491 40.105 28.5051 40.209 29.0531 40.209C29.9751 40.209 30.8751 39.916 31.5481 39.546C32.0401 39.276 32.4611 38.962 32.8671 38.658C33.2361 38.382 33.5851 38.121 33.9411 37.933C34.6101 37.579 35.3691 37.528 36.1341 37.791C36.7881 38.014 37.4981 37.667 37.7221 37.014C37.9461 36.361 37.5971 35.65 36.9451 35.426C35.5291 34.942 34.0481 35.046 32.7711 35.723C32.2451 36.001 31.8001 36.334 31.3711 36.655C31.0181 36.92 30.6841 37.169 30.3451 37.354C29.7111 37.703 28.9391 37.808 28.5101 37.601C28.3331 37.516 28.1911 37.236 27.9801 36.786C27.7401 36.275 27.4411 35.639 26.8541 35.15C25.8801 34.337 24.4961 34.107 23.0571 34.519C22.4761 34.685 21.9041 34.916 21.3501 35.141C19.0681 36.065 18.4161 36.146 17.7381 34.758C17.3111 33.883 16.4211 32.064 14.4131 31.972C13.1131 31.919 12.1061 32.466 11.2171 32.953C10.8151 33.174 10.4351 33.382 10.0741 33.507C9.5541 33.686 8.6861 33.701 8.3861 33.175C8.3061 33.034 8.2541 32.792 8.1991 32.535C8.1201 32.169 8.0311 31.755 7.8171 31.326C7.2551 30.202 6.1431 29.411 4.8421 29.21C4.5111 29.159 4.1471 29.144 3.7781 29.133C2.6591 29.098 2.5851 29.008 2.5221 28.808C2.3871 28.377 2.8771 27.624 3.4591 27.37C4.0911 27.093 4.3791 26.355 4.1021 25.724C3.8251 25.091 3.0891 24.804 2.4551 25.08C0.876103 25.772 -0.432898 27.735 0.135102 29.555C0.756102 31.541 2.6721 31.601 3.7001 31.632C3.9631 31.641 4.2221 31.645 4.4591 31.682C4.9631 31.759 5.3821 32.044 5.5821 32.444Z" fill="currentColor" />
    <path d="M87.463 31.96C87.308 30.859 86.472 29.078 85.102 28.561C84.457 28.319 83.733 28.643 83.489 29.289C83.245 29.935 83.569 30.655 84.215 30.899C84.458 31.014 84.915 31.791 84.988 32.309C85.111 33.183 84.294 33.969 84.04 34.19C83.479 34.676 82.79 34.948 81.988 34.996C81.688 35.017 81.331 34.972 80.948 34.927C80.24 34.844 79.436 34.75 78.612 34.983C77.231 35.375 76.664 36.542 76.251 37.393C76.173 37.553 76.097 37.712 76.016 37.86C75.674 38.483 75.071 39.146 74.307 39.104C74.055 39.092 73.729 38.995 73.384 38.891C72.543 38.638 71.392 38.292 70.197 38.939C69.408 39.366 69.009 40.064 68.687 40.625C68.522 40.914 68.366 41.187 68.217 41.313C67.449 41.958 66.13 41.947 65.21 41.285C64.951 41.099 64.719 40.883 64.485 40.668C63.814 40.054 62.98 39.289 61.703 39.113C60.213 38.906 58.657 39.537 57.072 40.981C56.015 41.942 55.474 41.941 55.043 41.803C54.352 41.575 53.611 40.88 53.158 40.029C52.833 39.42 52.078 39.188 51.467 39.513C50.858 39.838 50.627 40.595 50.951 41.204C51.715 42.637 52.953 43.749 54.264 44.178C54.669 44.311 55.057 44.367 55.424 44.367C57.064 44.367 58.293 43.249 58.754 42.829C59.451 42.194 60.478 41.473 61.36 41.589C61.84 41.655 62.218 41.983 62.795 42.51C63.088 42.78 63.391 43.058 63.75 43.315C65.617 44.657 68.173 44.62 69.828 43.223C70.314 42.812 70.601 42.311 70.854 41.868C71.035 41.554 71.221 41.227 71.385 41.139C71.637 41.003 71.967 41.078 72.661 41.286C73.114 41.423 73.628 41.577 74.188 41.603C75.791 41.659 77.305 40.702 78.204 39.063C78.306 38.879 78.4 38.684 78.497 38.486C78.794 37.876 79.009 37.468 79.29 37.389C79.633 37.289 80.128 37.349 80.653 37.411C81.128 37.467 81.62 37.521 82.137 37.493C83.48 37.412 84.703 36.923 85.675 36.079C87.017 34.915 87.668 33.415 87.463 31.96Z" fill="currentColor" />
  </svg>
);

export default SandwichIcon;
