import React, { FunctionComponent } from 'react';

const SmoothieIcon: FunctionComponent = () => (
  <svg width="41" height="68" viewBox="0 0 41 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.99915 38.8387L7.47313 66.66H32.9318L34.8501 30.515" stroke="currentColor" strokeWidth="2.16762" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M30.645 13.3041C31.9685 14.5245 33.0275 16.0035 33.7564 17.6496C34.4853 19.2957 34.8686 21.0739 34.8827 22.8741C34.8827 23.2787 34.861 23.687 34.8177 24.0988" stroke="currentColor" strokeWidth="2.16762" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.3381 24.088C5.29475 23.6833 5.27307 23.2751 5.27307 22.8632C5.27307 15.2766 11.8951 9.15308 20.0562 9.15308C20.497 9.15308 20.9341 9.17475 21.3676 9.2181" stroke="currentColor" strokeWidth="2.16762" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28.9326 2L24.6841 19.5794" stroke="currentColor" strokeWidth="2.16762" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.0136 24.088H39.5756V30.515H2V24.088H9.43493" stroke="currentColor" strokeWidth="2.16762" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SmoothieIcon;
