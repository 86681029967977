import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

interface Props {
  active: boolean;
  id: string;
  onClick: () => void;
}

const Tab: FunctionComponent<Props> = (props) => {
  const {
    active,
    children,
    id,
    onClick,
  } = props;

  return (
    <button
      aria-controls={`${id}-tab`}
      aria-selected={active}
      className={clsx(
        'mx-auto py-4 rounded-md shadow-lg text-center transition-colors w-36 hover:bg-green hover:bg-opacity-70 hover:text-white',
        active ? 'bg-green text-white' : 'bg-white text-green',
      )}
      id={id}
      role="tab"
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Tab;
